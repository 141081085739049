// extracted by mini-css-extract-plugin
export const container = "Why_work_with_us-module--container--1QawL";
export const text_lg = "Why_work_with_us-module--text_lg--26XF2";
export const text_orange = "Why_work_with_us-module--text_orange--Xcvkn";
export const text_light = "Why_work_with_us-module--text_light--3rdsb";
export const text_blue = "Why_work_with_us-module--text_blue--1jVvp";
export const icon = "Why_work_with_us-module--icon--3IZdI";
export const bouncy = "Why_work_with_us-module--bouncy--y5sjZ";
export const youtube = "Why_work_with_us-module--youtube--3Xd7S";
export const linkedin = "Why_work_with_us-module--linkedin--1gAuW";
export const instagram = "Why_work_with_us-module--instagram--hgsPC";
export const twitter = "Why_work_with_us-module--twitter--2jHdG";
export const youtube_light = "Why_work_with_us-module--youtube_light--2bMqz";
export const linkedin_light = "Why_work_with_us-module--linkedin_light--2NLXS";
export const instagram_light = "Why_work_with_us-module--instagram_light--38W-3";
export const twitter_light = "Why_work_with_us-module--twitter_light--3vfld";
export const shadow_button = "Why_work_with_us-module--shadow_button--8zkQp";
export const why_work_blue = "Why_work_with_us-module--why_work_blue--2hFfv";
export const why_work_title = "Why_work_with_us-module--why_work_title--2Iuu5";
export const work_reasons = "Why_work_with_us-module--work_reasons--2ntbt";
export const reasons_item = "Why_work_with_us-module--reasons_item--27e91";
export const reason = "Why_work_with_us-module--reason--1cu3A";
export const success_image = "Why_work_with_us-module--success_image--2QQ-E";
export const success_text = "Why_work_with_us-module--success_text--3V9W9";
export const find_out = "Why_work_with_us-module--find_out--VWwOn";
export const why_work_with_us = "Why_work_with_us-module--why_work_with_us--2Giwv";
export const innovation = "Why_work_with_us-module--innovation--1uJqb";
export const standards = "Why_work_with_us-module--standards--2KQ8h";
export const talent = "Why_work_with_us-module--talent--32zIK";
export const h3 = "Why_work_with_us-module--h3--1O7ef";
export const success = "Why_work_with_us-module--success--1Q3HM";
export const pop = "Why_work_with_us-module--pop--2Gq5e";