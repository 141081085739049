// extracted by mini-css-extract-plugin
export const business_solutions = "Benefits-module--business_solutions--3IrDA";
export const find_out_button = "Benefits-module--find_out_button--3dc1E";
export const solutions_flex = "Benefits-module--solutions_flex--25Z88";
export const first_text_section = "Benefits-module--first_text_section--3foAG";
export const solutions_li = "Benefits-module--solutions_li--2pTuP";
export const logo = "Benefits-module--logo--2Cg7-";
export const first_image_section = "Benefits-module--first_image_section--2NRdM";
export const how_benefit = "Benefits-module--how_benefit--10uvK";
export const get_to_know = "Benefits-module--get_to_know--SNVwa";
export const get_to_know_button = "Benefits-module--get_to_know_button--2g29H";
export const how_flex = "Benefits-module--how_flex--2v_Or";
export const second_text_section = "Benefits-module--second_text_section--2nXV7";
export const second_image_section = "Benefits-module--second_image_section--2DB7r";
export const second_image = "Benefits-module--second_image--3x5-8";
export const bouncy = "Benefits-module--bouncy--OLZMq";
export const pop = "Benefits-module--pop--1YOZh";