// extracted by mini-css-extract-plugin
export const business_solutions = "TopHomeInfo-module--business_solutions--2xCiU";
export const sub_heading = "TopHomeInfo-module--sub_heading--2Urqr";
export const find_out_button = "TopHomeInfo-module--find_out_button--2kveN";
export const solutions_flex = "TopHomeInfo-module--solutions_flex--LE0Iz";
export const first_text_section = "TopHomeInfo-module--first_text_section--1CS_Q";
export const solutions_li = "TopHomeInfo-module--solutions_li--1Hm4t";
export const logo = "TopHomeInfo-module--logo--1v2lk";
export const first_image_section = "TopHomeInfo-module--first_image_section--1Zu2j";
export const how_benefit = "TopHomeInfo-module--how_benefit--27lrV";
export const get_to_know = "TopHomeInfo-module--get_to_know--22pfe";
export const get_to_know_button = "TopHomeInfo-module--get_to_know_button--3wMB0";
export const how_flex = "TopHomeInfo-module--how_flex--3S9ea";
export const second_text_section = "TopHomeInfo-module--second_text_section--3aSis";
export const second_image_section = "TopHomeInfo-module--second_image_section--3oy_W";
export const second_image = "TopHomeInfo-module--second_image--2N481";
export const bouncy = "TopHomeInfo-module--bouncy--1YJFT";
export const pop = "TopHomeInfo-module--pop--3Bbxr";