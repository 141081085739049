import * as React from "react";
import { useState  } from "react";
import * as styles from "./StepSlider.module.scss";

import arrowLeftWorkflow from "@images/index_page/arrow_left_workflow.svg";
import arrowRightWorkflow from "@images/index_page/arrow_right_workflow.svg";

import contact from "@images/index_page/workflow/contact.svg";
import envision from "@images/index_page/workflow/envision.svg";
import develop from "@images/index_page/workflow/develop.svg";
import launch from "@images/index_page/workflow/launch.svg";


const workflow = [
  {
    heading: "Contact",
    text:
      "Schedule a consultation and we will discuss your needs in detail. From there, we will outline a strategy for how to elevate your business.",
    image: contact,
    alt: "people greeting"
  },
  {
    heading: "Envision",
    text:
      "Together, you and our team will design a software solution to meet your needs.",
    image: envision,
    alt: "pen writing"
  },
  {
    heading: "Develop",
    text:
      "With your vision in mind, we will create your software solution and conduct extensive testing to ensure optimal performance.",
    image: develop,
    alt: "pc with code"
  },
  {
    heading: "Launch",
    text:
      "You can launch your new system with confidence, knowing we monitor your product and can provide any support you may need.",
    image: launch,
    alt: "rocket launching"
  }
];

const IMAGES = {
  "contact": contact,
  "envision": envision,
  "develop": develop,
  "launch": launch
}

const Services = ({metadata}) => {
  const [step, setStep] = useState(0);
  console.log({metadata});
  return (
    <>
      <div className={styles.workflow}>
        <h3>{metadata.title}</h3>
        <section className={styles.workflow_steps}>
          {metadata.steps.map((singleStep, i) => (
            <div
              className={styles.workflow_step}
              key={i}
              onClick={() => {
                setStep(i);
              }}
              style={step === i ? { opacity: 1 } : { opacity: ".525" }}
            >
              <img className={styles.process_image} src={IMAGES[singleStep.image]} alt={singleStep.alt}/>
              <p className={styles.heading}>{singleStep.heading}</p>
            </div>
          ))}
        </section>
        <section className={`${styles.step_slides} container`}>
          <img
            src={arrowLeftWorkflow}
            alt="Go to previous"
            onClick={() => setStep(step === 0 ? 3 : step - 1)}
          />

          {metadata.steps.map(
            (workStep, i) =>
              step === i && (
                <div className={styles.step_text} key={`${i}-alt_img`}>
                  <h3>{`${i+1}. ${workStep.heading}`}</h3>
                  <p>{workStep.text}</p>
                </div>
              )
          )}
          
          <img
            src={arrowRightWorkflow}
            alt="Go to next"
            onClick={() => setStep(step === 3 ? 0 : step + 1)}
          />
        </section>
      </div>
    </>
  );
};

export default Services;
