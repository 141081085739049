import * as React from "react";
import * as styles from "./Benefits.module.scss";
import { Link } from "gatsby";

import websites from "@images/index_page/icon_websites.svg";
import apps from "@images/index_page/icon_apps.svg";
import analytics from "@images/index_page/icon_analytics.svg";
import e_commerce from "@images/index_page/icon_e_commerce.svg";
import marketing from "@images/index_page/icon_marketing.svg";
import design from "@images/index_page/icon_design.svg";
import development from "@images/index_page/icon_developers.svg";
import checkmark from "@images/index_page/checkmark_orange.svg";

const benefits = [
  "Let us solve your complex technical infrastructure so you can focus on your business goals.",
  "Ensure you are making the most of modern tools by having our expert team review your processes.",
  "Get seamless, scalable solutions to grow your business without worrying about the technical backend."
];

const solutions = [
  {
    title: "Websites",
    img: websites
  },
  {
    title: "Apps",
    img: apps
  },
  {
    title: "Analytics",
    img: analytics
  },
  {
    title: "E-commerce",
    img: e_commerce
  },
  {
    title: "Development",
    img: development
  },
  {
    title: "Design",
    img: design
  },
  {
    title: "Marketing",
    img: marketing
  }
];

const TopHomeInfo = () => (
  <section className="">
    
    <article className={styles.how_benefit}>
      <div className={`${styles.how_flex} container`}>
        <div className={styles.second_text_section}>
          <h2>How you and your business can benefit from our services</h2>
          <ul>
            {benefits.map((benefit, i) => (
              <li key={i}>
                <img src={checkmark} alt="Checkmark icon" />
                <p>{benefit}</p>
              </li>
            ))}
          </ul>
        </div>
        <div className={styles.second_image_section}>
          <h3 className={styles.get_to_know}>
            Sounds good?
          </h3>

          <button className={styles.get_to_know_button}>
            <Link to="#contact-form">Let's talk!</Link>
          </button>
        </div>
      </div>
    </article>

    
  </section>
);

export default TopHomeInfo;
