import * as React from "react";
import { graphql } from "gatsby"
import Layout from "@components/layout";
import HomeComponent from "@components/Home";
import SEO from "@components/seo";
import { MetadataService } from "../utils/MetadataService";

const IndexPage = ({data}) => {

  const { pageMetadata } = data;
  const metadata = MetadataService.parseMetadata(JSON.parse(JSON.stringify(pageMetadata || {}))).content;
  
  return (
    <Layout>
      <SEO title={metadata.page_title} />
      <h1 style={{display: "none"}}>{metadata.hidden_h1}</h1>
      <HomeComponent metadata={metadata} />
      <p>
        {/* <Link to="/page-2/">Go to page 2</Link> <br /> */}
        {/* <Link to="/using-typescript/">Go to "Using TypeScript"</Link> */}
      </p>
    </Layout>
  );
}

export const pageQuery = graphql`
  query HomepageComponentQuery {
    pageMetadata(page_id: {eq: "index"}) {
      content,
      page_id
    }
  }
`

export default IndexPage;
