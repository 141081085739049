// extracted by mini-css-extract-plugin
export const workflow = "StepSlider-module--workflow--1vzP9";
export const workflow_steps = "StepSlider-module--workflow_steps--3yX_T";
export const workflow_step = "StepSlider-module--workflow_step--1uQfi";
export const process_image = "StepSlider-module--process_image--3KZQE";
export const bouncy = "StepSlider-module--bouncy--3xW3h";
export const step_text = "StepSlider-module--step_text--2kFZe";
export const appear = "StepSlider-module--appear--2pewm";
export const step_slides = "StepSlider-module--step_slides--2HhVF";
export const heading = "StepSlider-module--heading--H3_sO";
export const pop = "StepSlider-module--pop--F6O5I";