import * as React from "react";
import TopHomeInfo from "./TopHomeInfo";
import Benefits from "./Benefits";
import StepSlider from "./Services/StepSlider"
import WhyWorkWithUs from "./Why_work_with_us";
import ContactForm from "../ContactForm";
import * as styles from "./Home.module.scss";


const HomeComponent = ({metadata}) => {
  return (
    <>
      <div className={styles.title}>
      <h1>{metadata.title}</h1>
    </div>
    <div className={styles.top_blue}></div>
    <TopHomeInfo metadata={metadata.topHomeInfo} />

    <section style={{ marginTop: "80px" }}>
      <WhyWorkWithUs metadata={metadata.whyWorkWithUs}/>
    </section>
    <StepSlider metadata={metadata.stepSlider} />

    <Benefits />
    <section className="container">
      <ContactForm />
    </section>
  </>
);
}

export default HomeComponent;
