import * as React from "react";
import * as styles from "./TopHomeInfo.module.scss";
import { Link } from "gatsby";

import websites from "@images/index_page/icon_websites.svg";
import apps from "@images/index_page/icon_apps.svg";
import analytics from "@images/index_page/icon_analytics.svg";
import e_commerce from "@images/index_page/icon_e_commerce.svg";
import marketing from "@images/index_page/icon_marketing.svg";
import design from "@images/index_page/icon_design.svg";
import development from "@images/index_page/icon_developers.svg";

import bunny from "@images/index_page/logo1.svg";
import checkmark from "@images/index_page/checkmark_orange.svg";

import arrowDown from "@images/index_page/arrow_down_cta.svg";

const benefits = [
  "Let us solve your complex technical infrastructure so you can focus on your business goals.",
  "Ensure you are making the most of modern tools by having our expert team review your processes.",
  "Get seamless, scalable solutions to grow your business without worrying about the technical backend."
];

const solutions = [
  {
    title: "Websites",
    img: websites
  },
  {
    title: "Apps",
    img: apps
  },
  {
    title: "Analytics",
    img: analytics
  },
  {
    title: "E-commerce",
    img: e_commerce
  },
  {
    title: "Development",
    img: development
  },
  {
    title: "Design",
    img: design
  },
  {
    title: "Marketing",
    img: marketing
  }
];

const IMAGES = {
  "websites": websites,
  "apps": apps,
  "analytics": analytics,
  "e_commerce": e_commerce,
  "development": development,
  "design": design,
  "marketing": marketing,
  "checkmark": checkmark,
  "arrowDown": arrowDown
}

const TopHomeInfo = ({metadata}) => (
  <section className="">
    <article className={`${styles.business_solutions} container`}>
      <Link to="/services/marketing" className={styles.find_out_button}>
      <span>{metadata.title}</span>
      </Link>
      <div className={styles.solutions_flex}>
        <section className={styles.first_text_section}>
          <h2 className={styles.sub_heading}>
            {metadata.text2}
          </h2>
          <div dangerouslySetInnerHTML={{__html: metadata.text3}} style={{fontWeight: "bold", color: "red"}}></div>
          <div style={{ display: "flex" }}>
            <ul className={styles.solutions_li}>
              {metadata.solutions.map(solution => (
                <li key={solution.title} key={solution.title}>
                  {solution.title}{" "}
                  <img src={IMAGES[solution.img]} alt={solution.title} />
                </li>
              ))}
            </ul>
            
          </div>
        </section>
        <img src={bunny} className={styles.first_image_section} />
        {/* <section className={styles.first_image_section}></section> */}
      </div>
    </article>
    
    
    {/* <article className={styles.how_benefit}>
      <div className={`${styles.how_flex} container`}>
        <div className={styles.second_text_section}>
          <h2>How you and your business can benefit from our services</h2>
          <ul>
            {benefits.map((benefit, i) => (
              <li key={i}>
                <img src={checkmark} alt="Checkmark icon" />
                <p>{benefit}</p>
              </li>
            ))}
          </ul>
        </div>
        <div className={styles.second_image_section}>
          <div className={styles.second_image}></div>
          <h4 className={styles.get_to_know}>
            Sounds good?
          </h4>

          <button className={styles.get_to_know_button}>
            <Link to="#contact-form">Let's talk!</Link>
          </button>
        </div>
      </div>
    </article> */}


  </section>
);

export default TopHomeInfo;
