import * as React from "react";
import { Link } from "gatsby";
import * as styles from "./Why_work_with_us.module.scss";

import innovation from "@images/index_page/work_with_us_innovation.svg";
import standards from "@images/index_page/work_with_us_standards.svg";
import talent from "@images/index_page/work_with_us_talent.svg";

const reasons = [
  {
    name: "innovation",
    text: "We strive for excellence and innovation",
    image: innovation
  },
  {
    name: "standards",
    text: "We work to high standards",
    image: standards
  },
  {
    name: "talent",
    text: "We hire top talent from all over the world",
    image: talent
  }
];

const IMAGES = {
  "innovation": innovation,
  "standards": standards,
  "talent": talent
};

const WorkReason = props => {
  const { image, text } = props;
  return (
    <li className={styles.reasons_item}>
      <div
        className={styles.reason}
        style={{ backgroundImage: `url(${image})` }}
      ></div>
      <p>{text}</p>
    </li>
  );
};

const WhyWorkWithUs = ({metadata}) => (
  <div className={styles.why_work_blue}>
    <div className="container">
      <section className={styles.why_work_with_us}>
        <h2 className={styles.why_work_title}>{metadata.title}</h2>
        <div dangerouslySetInnerHTML={{__html: metadata.content}}>
          
        </div>
      </section>

      <ul className={styles.work_reasons}>
        {metadata.reasons.map(reason => (
          <WorkReason {...reason} key={reason.name} image={IMAGES[reason.image]} />
        ))}
      </ul>

      {/* <h3>See Some Of Our Past Success</h3> */}

      {/* <section className={styles.success}>
        <div className={styles.success_image}></div>
        <div className={styles.success_text}>
          <p>
            Get a deeper insight on how our team develops industry leading
            software, websites and applications...
          </p>
          <Link to="/aboutUs">
            <button className={styles.find_out}>
              Find Out
            </button>
          </Link>
        </div>
      </section> */}
    </div>
  </div>
);

export default WhyWorkWithUs;
